import './i18n';

import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import LoadingSpinner from 'components/common/LoadingSpinner/LoadingSpinner';
import 'react-loading-skeleton/dist/skeleton.css';

const app = (
    <Suspense fallback={<LoadingSpinner style={{position: 'fixed', top: '0', left: '50%'}} />}>
        <App />
    </Suspense>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
