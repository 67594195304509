import React, { createContext } from 'react';
import AsyncStateComponent from 'context/AsyncStateComponent';
import { debounce } from 'lodash';

import { UserContextConsumer } from 'context/UserContext';

import {
    INTERVIEW_INSIDE_COMPANY_STATUS_LIST,
    INTERVIEW_OUTSIDE_COMPANY_STATUS_LIST,
} from 'components/Interview/interviewConstants';
import { requestHelperWithInitialState } from 'helpers/requestHelper';
import { isCompanyAdmin, isResource } from 'constants/UserRoles';
import {
    defaultProjectOptions,
    lists,
    interviewersLists,
    getInitialStateForList,
} from 'constants/InterviewStorageConstants';

import {
    handleApplicationDeclined,
    handleHiredToInterview,
    handleInterviewCancelled,
    handleInvitationCancelled,
    handleNewInterview,
    handleNewInvitation,
    handleCancelHire,
} from 'context/Interview/storage/storageSocketMethods';

import makeRequest from 'makeRequest';

import BackendSocket from 'controllers/BackendSocket';
import ResourceStatusInformation from 'constants/ResourceStatusInformation';
import { getToken } from 'helpers/getToken';

export const InterviewStorageContext = createContext({});

const getProjectFilterOptionsRequestHelper = requestHelperWithInitialState(
    'getProjectFilterOptions',
    defaultProjectOptions,
);
const getClientFilterOptionsRequestHelper = requestHelperWithInitialState('getClientFilterOptions', { list: [] });

class InterviewStorageContextProvider extends AsyncStateComponent {
    constructor(props) {
        super(props);
        this.state = {
            ...getInitialStateForList(lists.browseInterviews),
            ...getInitialStateForList(lists.interviews),
            ...getInitialStateForList(lists.projectInterviews),
            ...getInitialStateForList(lists.publicInterviews),
            ...getInitialStateForList(lists.featuredInterviews),
            ...getInitialStateForList(interviewersLists.inviteInterviewers),
            ...getInitialStateForList(interviewersLists.applications),
            ...getInitialStateForList(interviewersLists.invitations),

            interview: null,
            interviewProcessing: false,
            interviewError: null,
            selectedInterviewers: [],
            updateQueue: [],

            hiredResources: [],
            hiredResourcesSessionFilter: '',
            hiredResourcesTextFilter: '',

            interviewTab: '',
            resourceListPage: '',
            verticalView: false,
            featuredClicked: false,
            ...getProjectFilterOptionsRequestHelper.initialState,
            ...getClientFilterOptionsRequestHelper.initialState,
            isBrowseInterviewsLoaded: false,
            interviewDetailsReturnPage: '',
        };

        window.isc = this;
    }

    componentDidUpdate(prevProps) {
        const { user: oldUser } = prevProps.userContext;
        const { user: newUser } = this.props.userContext;
        const userAppeared = !oldUser && newUser;
        const userChanged = oldUser && newUser && newUser.id !== oldUser.id;
        const noStatusSetForUser = newUser && !this.state.filter.status;
        if (userChanged || userAppeared || noStatusSetForUser) {
            //Settings default filter status for new user
            const statusToSet = isResource(newUser.role)
                ? INTERVIEW_OUTSIDE_COMPANY_STATUS_LIST[2].value
                : INTERVIEW_INSIDE_COMPANY_STATUS_LIST[1].value;
            this.updateFilterAndFetch(lists.interviews, { filter: { status: statusToSet } });
            if (isResource(newUser.role)) {
                this.updateFilterAndFetch(lists.browseInterviews, {
                    filter: { language: newUser.languages, role: newUser.role },
                });
            }
            this.applySocketListeners();
            return;
        }
        if (!newUser) {
            this.removeSocketListeners();
            if (oldUser) {
                this.updateFilterAndFetch(lists.publicInterviews, {});
                this.clearInterview();
                this.clearUpdateQueue();
            }
        }
    }

    fetchInterview = async (filter) => {
        const data = {
            authToken: getToken(),
            filter,
        };
        const path =
            data.authToken && data.authToken !== 'null' ? 'interview-storage/single' : 'interview-storage/publicSingle';
        await this.setStatePromise({ interviewProcessing: true });
        try {
            const result = await makeRequest({ path, data });
            await this.setStatePromise({
                interview: result,
                interviewProcessing: false,
                interviewError: null,
            });
            return result;
        } catch (e) {
            await this.setStatePromise({
                interviewProcessing: false,
                interviewError: e,
            });
        }
    };
    clearInterview = () => this.setState({ interview: null, interviewProcessing: false, interviewError: null });

    fetchSession = async ({ token }) => {
        if (!token) return;
        const path = 'interview-storage/sessionInfo';
        const data = { token };

        try {
            return await makeRequest({ path, data });
        } catch (e) {
            console.log(e);
        }
    };

    fetchInterviewsList = async (list, { filter, offset, sort, limit }) => {
        const data = {
            authToken: getToken(),
            filter,
            offset,
            limit,
            sort,
        };
        let path =
            data.authToken && data.authToken !== 'null' ? 'interview-storage/list' : 'interview-storage/publicList';
        if (list.result === lists.featuredInterviews.result) {
            path =
                data.authToken && data.authToken !== 'null'
                    ? 'interview-storage/randomList'
                    : 'interview-storage/randomPublicList';
        }
        await this.setStatePromise({ [list.processing]: true });
        try {
            const { list: interviewsList, totalCount } = await makeRequest({ path, data });
            await this.setStatePromise({
                [list.processing]: false,
                [list.result]: { list: interviewsList, totalCount },
            });
        } catch (e) {
            await this.setStatePromise({
                [list.processing]: false,
                [list.error]: e,
            });
        }
    };
    debouncedFetchInterviewsList = debounce(this.fetchInterviewsList, 200);

    updateFilterAndFetch = async (list, args) => {
        const {
            filter = this.state[list.filter],
            sort = this.state[list.sort],
            limit = this.state[list.limit],
            offset = this.state[list.offset],
        } = args;
        await this.setStatePromise({
            [list.filter]: { ...this.state[list.filter], ...filter },
            [list.offset]: offset,
            [list.limit]: limit,
            [list.sort]: sort,
        });
        const data = {
            filter: this.state[list.filter],
            sort: this.state[list.sort],
            offset: this.state[list.offset],
            limit: this.state[list.limit],
        };
        if (Object.keys(filter).some((key) => ['query', 'location'].includes(key))) {
            return await this.debouncedFetchInterviewsList(list, data);
        }
        await this.fetchInterviewsList(list, data);
        if (
            !this.state.isBrowseInterviewsLoaded &&
            (list.result === 'browseInterviews' || list.result === 'publicInterviews')
        ) {
            this.setState({ isBrowseInterviewsLoaded: true });
        }
    };

    getRecordingLinkStatus = async ({ interviewId, sessionId, videoKey }) => {
        const path = 'interview-storage/getRecordingLinkStatus';
        const authToken = getToken();
        const data = {
            authToken,
            interviewId,
            sessionId,
            videoKey,
        };
        try {
            return await makeRequest({ path, data });
        } catch (e) {
            console.log(e);
        }
    };

    generateOrRemoveRecordingLink = async ({ interviewId, sessionId, videoKey }) => {
        const path = 'interview-storage/generateOrRemoveRecordingLink';
        const authToken = getToken();
        const data = {
            authToken,
            interviewId,
            sessionId,
            videoKey,
        };
        try {
            return await makeRequest({ path, data });
        } catch (e) {
            console.log(e);
        }
    };

    updateInterviewInStorage = async (interviewId) => {
        const { user } = this.props.userContext;
        const {
            browseInterviews: { list: browseList },
            projectInterviews: { list: projectInterviewsList },
            interviews: { list },
            interview,
        } = this.state;
        if (list.some((interview) => interview._id === interviewId)) {
            await this.updateFilterAndFetch(lists.interviews, {});
        }

        const isRes = isResource(user.role);
        const isInBrowseInterviews = browseList.some((interview) => interview._id === interviewId);
        if (isRes && isInBrowseInterviews) {
            await this.updateFilterAndFetch(lists.browseInterviews, {});
        }

        const isClient = isCompanyAdmin(user.role);
        const isInProjectInterviews = projectInterviewsList.some((interview) => interview._id === interviewId);
        if (isClient && isInProjectInterviews) {
            await this.updateFilterAndFetch(lists.projectInterviews, {});
        }

        if (interview && interview._id === interviewId) {
            await this.fetchInterview({ identifier: interviewId });
        }
    };

    getProjectFilterOptions = async () => {
        const authToken = getToken();
        const path = 'interviews/getProjectFilterOptions';

        this.setState(getProjectFilterOptionsRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data: { authToken } });
            this.setState(getProjectFilterOptionsRequestHelper.result(result));
        } catch (e) {
            this.setState(getProjectFilterOptionsRequestHelper.error(e));
        }
    };

    getClientFilterOptions = async () => {
        const authToken = getToken();
        const path = 'interviews/getClientFilterOptions';

        this.setState(getClientFilterOptionsRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data: { authToken } });
            this.setState(getClientFilterOptionsRequestHelper.result(result));
        } catch (e) {
            this.setState(getClientFilterOptionsRequestHelper.error(e));
        }
    };

    changeView = () => {
        this.setState({ verticalView: !this.state.verticalView });
    };

    setFeaturedClick = (value) => {
        this.setState({ featuredClick: value });
    };

    resetFilter = async (list) => {
        const { user } = this.props.userContext;
        await this.setStatePromise({
            [list.filter]: { ...list.defaultFilter, role: user && user.role, language: user && user.languages },
            [list.offset]: 0,
            [list.sort]: list.defaultSort,
        });
        const data = {
            filter: this.state[list.filter],
            sort: this.state[list.sort],
            offset: this.state[list.offset],
            limit: this.state[list.limit],
        };
        await this.fetchInterviewsList(list, data);
    };

    updateInterviewersFilterAndFetch = async (list, args) => {
        const {
            filter = this.state[list.filter],
            sort = this.state[list.sort],
            limit = this.state[list.limit],
            offset = this.state[list.offset],
        } = args;
        await this.setStatePromise({
            [list.filter]: { ...this.state[list.filter], ...filter },
            [list.offset]: offset,
            [list.limit]: limit,
            [list.sort]: sort,
        });

        const data = {
            filter: this.state[list.filter],
            offset: this.state[list.offset],
            limit: this.state[list.limit],
            sort: this.state[list.sort],
        };
        await this.debouncedFetchInterviewers(list, data);
    };
    fetchInterviewers = async (list, { filter, offset, limit, sort }) => {
        const path = 'interview-storage/interviewers';
        const authToken = getToken();
        const data = {
            authToken,
            filter,
            offset,
            limit,
            sort,
        };
        await this.setStatePromise({ [list.processing]: true });
        try {
            const result = await makeRequest({ path, data });
            this.setState({
                [list.processing]: false,
                [list.result]: result,
                [list.error]: null,
            });
        } catch (e) {
            this.setState({
                [list.processing]: false,
                [list.result]: { list: [], totalCount: 0 },
                [list.error]: e,
            });
        }
    };
    debouncedFetchInterviewers = debounce(this.fetchInterviewers, 200);
    resetInterviewersFilter = (list) => {
        this.updateInterviewersFilterAndFetch(list, {
            filter: {
                ...list.defaultFilter,
                role: this.state[list.filter].role || list.defaultFilter.role,
                interview: this.state[list.filter].interview,
                ids: this.state[list.filter].ids,
            },
            offset: 0,
            sort: { key: 'applicationDate', value: 1 },
        });
    };

    updateHiredResourcesFilter = (update) => {
        const { session = this.state.hiredResourcesSessionFilter, text = this.state.hiredResourcesTextFilter } = update;
        this.setState({ hiredResourcesSessionFilter: session, hiredResourcesTextFilter: text });
    };
    updateHiredResources = async ({ interviewId, resourcesIds = [] }) => {
        const path = 'interview-storage/interviewers';
        const authToken = getToken();
        const data = {
            authToken,
            filter: { interview: interviewId, ids: resourcesIds },
            offset: 0,
            limit: 1000,
        };

        try {
            const result = await makeRequest({ path, data });
            this.setState({ hiredResources: result.list });
        } catch (e) {
            this.setState({ hiredResources: [] });
        }
    };
    clearHiredResources = () => this.setState({ hiredResources: [] });

    enqueueListUpdate = (interviewId) => {
        this.setState({
            updateQueue: [...this.state.updateQueue, interviewId],
        });
    };
    clearUpdateQueue = () => {
        this.setState({
            updateQueue: [],
        });
    };

    increaseLocalShareCounter = (destination) => {
        this.setState((prevState) => ({
            interview: {
                ...this.state.interview,
                shareCounts: {
                    ...this.state.interview.shareCounts,
                    [destination]: prevState.interview.shareCounts[destination] + 1,
                },
            },
        }));
    };

    setInterviewTab = (tab) => this.setState({ interviewTab: tab });
    setResourceListPage = (page) => this.setState({ resourceListPage: page });

    updateResourceListForResourceAction = async (interviewId, list) => {
        const { interview } = this.state;
        if (interview && interview._id === interviewId) {
            if (list === interviewersLists.applications || !list) {
                const applicantsIds = interview.resources
                    .filter((res) => ResourceStatusInformation.isApplication(res.status))
                    .map((res) => res.user);
                const filter = { ids: applicantsIds };
                await this.updateInterviewersFilterAndFetch(interviewersLists.applications, { filter });
                return;
            }

            if (list === interviewersLists.invitations) {
                const invitationsIds = interview.resources
                    .filter((res) => ResourceStatusInformation.isInvitation(res.status))
                    .map((res) => res.user);
                const filter = { ids: invitationsIds };
                await this.updateInterviewersFilterAndFetch(interviewersLists.invitations, { filter });
                return;
            }

            if (list === interviewersLists.inviteInterviewers) {
                await this.updateInterviewersFilterAndFetch(interviewersLists.inviteInterviewers, {});
            }
        }
    };
    setReturnPageFromInterviewDetails = (page) => this.setState({ interviewDetailsReturnPage: page });

    getRecordingSignedURL = async ({ key, interviewId }) => {
        const path = 'interview-storage/getRecordingSignedURL';
        const authToken = getToken();

        try {
            return await makeRequest({ path, data: { key, interviewId, authToken } });
        } catch (error) {
            console.log(error);
        }
    };

    getPublicRecordingSignedURL = async ({ key, interviewId, token }) => {
        const path = 'interview-storage/getPublicRecordingSignedURL';
        try {
            return await makeRequest({ path, data: { key, interviewId, token } });
        } catch (error) {
            console.log(error);
        }
    };

    addSelectedUser = ({ user }) => {
        this.setState({
            selectedInterviewers: [...this.state.selectedInterviewers, user],
        });
    };

    removeSelectedUser = ({ user }) => {
        const filteredList = [...this.state.selectedInterviewers.filter((u) => u._id !== user._id)];
        this.setState({
            selectedInterviewers: filteredList,
        });
    };

    clearSelectedUsers = () => this.setState({ selectedInterviewers: [] });

    fetchAvailableCountries = async () => {
        const { interview } = this.state;
        const path = 'interview-storage/filteredCountries';
        const authToken = getToken();

        try {
            return await makeRequest({ path, data: { authToken, interviewId: interview._id } });
        } catch (error) {
            console.log(error);
        }
    };

    restoreEmail = async (resourceId) => {
        const { interview } = this.state;
        const path = 'interview-storage/restoreEmail';
        const authToken = getToken();

        try {
            return await makeRequest({ path, data: { authToken, interviewId: interview._id, resourceId } });
        } catch (error) {
            console.log(error);
        }
    };

    applySocketListeners = () => {
        BackendSocket.on('interview:invite', handleNewInvitation.bind(this));
        BackendSocket.on('interview:hired', handleHiredToInterview.bind(this));
        BackendSocket.on('interview:cancelled', handleInterviewCancelled.bind(this));
        BackendSocket.on('interview:new', handleNewInterview.bind(this));
        BackendSocket.on('interview:reschedule', async (interviewId) => {
            await this.updateInterviewInStorage(interviewId);
        });
        BackendSocket.on('interview:applicationDeclined', handleApplicationDeclined.bind(this));
        BackendSocket.on('interview:invitationCancelled', handleInvitationCancelled.bind(this));
        BackendSocket.on('interview:cancelHire', handleCancelHire.bind(this));
        BackendSocket.on('interview:update', async (interviewId) => {
            await this.updateInterviewInStorage(interviewId);
        });
        BackendSocket.on('interview:resource', async (interviewId) => {
            await this.updateInterviewInStorage(interviewId);
            await this.updateResourceListForResourceAction(interviewId);
        });
        BackendSocket.on('interview:hiredResources', (interviewId) => {
            const { interview } = this.state;
            if (interviewId !== interview._id) return;
            const hiredIds = interview.resources
                .filter((res) => {
                    const statusMatch =
                        ResourceStatusInformation.isHired(res.status) ||
                        ResourceStatusInformation.isHiredCancelled(res.status);
                    return statusMatch;
                })
                .map((res) => res.user);
            this.updateHiredResources({ interviewId, resourcesIds: hiredIds });
        });
    };

    removeSocketListeners = () => {
        BackendSocket.off('interview:invite');
        BackendSocket.off('interview:hired');
        BackendSocket.off('interview:cancelled');
        BackendSocket.off('interview:new');
        BackendSocket.off('interview:reschedule');
        BackendSocket.off('interview:applicationDeclined');
        BackendSocket.off('interview:invitationCancelled');
        BackendSocket.off('interview:update');
        BackendSocket.off('interview:resource');
    };

    render() {
        const actions = {
            //browse/my-interviews/project based fields
            updateInterviewsFilterAndFetch: this.updateFilterAndFetch.bind(this, lists.interviews),
            resetInterviewsFilter: this.resetFilter.bind(this, lists.interviews),

            updateBrowseFilterAndFetch: this.updateFilterAndFetch.bind(this, lists.browseInterviews),
            resetBrowseFilter: this.resetFilter.bind(this, lists.browseInterviews),

            updateProjectInterviewsFilterAndFetch: this.updateFilterAndFetch.bind(this, lists.projectInterviews),
            resetProjectInterviewsFilter: this.resetFilter.bind(this, lists.projectInterviews),

            updatePublicInterviewsFilterAndFetch: this.updateFilterAndFetch.bind(this, lists.publicInterviews),
            resetPublicInterviewsFilter: this.resetFilter.bind(this, lists.publicInterviews),

            updateFeaturedInterviewsFilterAndFetch: this.updateFilterAndFetch.bind(this, lists.featuredInterviews),
            resetFeaturedInterviewsFilter: this.resetFilter.bind(this, lists.featuredInterviews),

            //common
            changeView: this.changeView,
            setFeaturedClick: this.setFeaturedClick,
            getProjectFilterOptions: this.getProjectFilterOptions,
            fetchInterview: this.fetchInterview,
            updateInterviewInStorage: this.updateInterviewInStorage,
            clearInterview: this.clearInterview,

            updateInviteInterviewersFilterAndFetch: this.updateInterviewersFilterAndFetch.bind(
                this,
                interviewersLists.inviteInterviewers,
            ),
            resetInviteInterviewersFilter: this.resetInterviewersFilter.bind(
                this,
                interviewersLists.inviteInterviewers,
            ),

            updateInvitationsFilterAndFetch: this.updateInterviewersFilterAndFetch.bind(
                this,
                interviewersLists.invitations,
            ),
            resetInvitationsFilter: this.resetInterviewersFilter.bind(this, interviewersLists.invitations),

            updateApplicationsFilterAndFetch: this.updateInterviewersFilterAndFetch.bind(
                this,
                interviewersLists.applications,
            ),
            resetApplicationsFilter: this.resetInterviewersFilter.bind(this, interviewersLists.applications),

            updateHiredResourcesFilter: this.updateHiredResourcesFilter,
            updateHiredResources: this.updateHiredResources,
            clearHiredResources: this.clearHiredResources,

            enqueueListUpdate: this.enqueueListUpdate,
            clearUpdateQueue: this.clearUpdateQueue,
            increaseLocalShareCounter: this.increaseLocalShareCounter,
            setInterviewTab: this.setInterviewTab,
            setResourceListPage: this.setResourceListPage,
            updateResourceListForResourceAction: this.updateResourceListForResourceAction,
            setReturnPageFromInterviewDetails: this.setReturnPageFromInterviewDetails,
            getRecordingSignedURL: this.getRecordingSignedURL,
            getClientFilterOptions: this.getClientFilterOptions,
            setScreenerFilter: this.setScreenerFilter,

            addSelectedUser: this.addSelectedUser,
            removeSelectedUser: this.removeSelectedUser,
            clearSelectedUsers: this.clearSelectedUsers,
            fetchAvailableCountries: this.fetchAvailableCountries,
            restoreEmail: this.restoreEmail,
            fetchSession: this.fetchSession,
            getRecordingLinkStatus: this.getRecordingLinkStatus,
            generateOrRemoveRecordingLink: this.generateOrRemoveRecordingLink,
            getPublicRecordingSignedURL: this.getPublicRecordingSignedURL,
        };

        return (
            <InterviewStorageContext.Provider value={{ ...this.state, ...actions }}>
                {this.props.children}
            </InterviewStorageContext.Provider>
        );
    }
}

export default UserContextConsumer(InterviewStorageContextProvider);

export const InterviewStorageContextConsumer = function(WrappedComponent) {
    return function(props) {
        return (
            <InterviewStorageContext.Consumer>
                {(context) => <WrappedComponent interviewStorageContext={context} {...props} />}
            </InterviewStorageContext.Consumer>
        );
    };
};
