import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Routes from 'constants/Routes';

import CheckAuth from 'components/Router/CheckAuth';
const AdminPage = React.lazy(() => import('pages/admin/AdminPage'));
const AdminProjectsPage = React.lazy(() => import('pages/admin/AdminProjectsPage'));
const AdminProjectDetailPage = React.lazy(() => import('pages/admin/AdminProjectDetailPage'));
const AdminUserInfoPage = React.lazy(() => import('pages/admin/AdminUserInfoPage'));
const AdminCreateAdminPage = React.lazy(() => import('pages/admin/AdminCreateAdminPage'));
const AdminCreateUserPage = React.lazy(() => import('pages/admin/AdminCreateUserPage'));
const Register = React.lazy(() => import('pages/auth/RegisterPage'));
const ConfirmPage = React.lazy(() => import('pages/auth/ConfirmPage'));
const LoginPage = React.lazy(() => import('pages/auth/LoginPage'));
const ResetPasswordPage = React.lazy(() => import('pages/auth/ResetPasswordPage'));
const ProjectsPage = React.lazy(() => import('pages/project/ProjectsPage'));
const NewProjectPage = React.lazy(() => import('pages/project/NewProjectPage'));
const ProjectDetailPage = React.lazy(() => import('pages/project/ProjectDetailPage'));
const InterviewsPage = React.lazy(() => import('pages/interview/InterviewsPage'));
const ProfileOrWelcomePage = React.lazy(() => import('pages/profile/ProfileOrWelcomePage'));
const WelcomePage = React.lazy(() => import('pages/WelcomePage'));
const ProfilePage = React.lazy(() => import('pages/profile/ProfilePage'));
const ProfileEditPage = React.lazy(() => import('pages/profile/ProfileEditPage'));
const InterviewDetailPage = React.lazy(() => import('pages/interview/InterviewDetailPage'));
const EditProjectPage = React.lazy(() => import('pages/project/EditProjectPage'));
const BrowsePage = React.lazy(() => import('pages/interview/BrowsePage'));
const CheckoutPage = React.lazy(() => import('pages/payIn/CheckoutPage'));
const PayInResultPage = React.lazy(() => import('pages/payIn/PayInResultPage'));
const ScreenersPage = React.lazy(() => import('pages/screener/ScreenersPage'));
const CreateScreenerPage = React.lazy(() => import('pages/screener/CreateScreenerPage'));
const EditScreenerPage = React.lazy(() => import('pages/screener/EditScreenerPage'));
const ConfirmRegistration = React.lazy(() =>
    import('components/Auth/Register/ConfirmRegistration/ConfirmRegistration'),
);
const ProfilePublicPage = React.lazy(() => import('pages/profile/ProfilePublicPage'));
const ChatPage = React.lazy(() => import('pages/ChatPage'));
const InterviewPage = React.lazy(() => import('pages/interview/InterviewPage'));
const AdminPlansPage = React.lazy(() => import('pages/admin/AdminPlansPage'));
const ObserverPage = React.lazy(() => import('pages/interview/ObserverPage'));
const RecordingPage = React.lazy(() => import('pages/recording/RecordingPage'));
const DisputesPage = React.lazy(() => import('pages/dispute/DisputesPage'));
const AdminOrdersPage = React.lazy(() => import('pages/admin/AdminOrdersPage'));
const NotificationsPage = React.lazy(() => import('pages/NotificationsPage'));
const VouchersPage = React.lazy(() => import('pages/admin/VouchersPage'));
const PromoCodesPage = React.lazy(() => import('pages/admin/PromoCodesPage'));
const JoinExternalPage = React.lazy(() => import('pages/interview/JoinExternalPage'));
const DashboardPage = React.lazy(() => import('pages/dashboard/DashboardPage'));
const WaitingRoomPage = React.lazy(() => import('pages/interview/WaitingRoomPage'));
const ModeratorWaitingRoomPage = React.lazy(() => import('pages/interview/ModeratorWaitingRoomPage'));
const NewInterviewCreationFormPage = React.lazy(() => import('pages/interview/NewInterviewCreationPage'));
const InstanceWaitingRoomPage = React.lazy(() => import('pages/interview/InstanceWaitingRoomPage'));
const InviteResourcesPage = React.lazy(() => import('pages/interview/InviteResourcesPage'));
const ApplicationsPage = React.lazy(() => import('pages/interview/ApplicationsPage'));
const InvitationsPage = React.lazy(() => import('pages/interview/InvitationsPage'));
const TeamRegisterPage = React.lazy(() => import('pages/auth/TeamRegisterPage'));
const ExpiredLinkPage = React.lazy(() => import('pages/auth/ExpiredLinkPage'));
const CreateRolePage = React.lazy(() => import('pages/roles/CreateRolePage'));
const ViewRolePage = React.lazy(() => import('pages/roles/ViewRolePage'));
const EditRolePage = React.lazy(() => import('pages/roles/EditRolePage'));
const ToastNotifications = React.lazy(() => import('components/Notifications/ToastNotifications/ToastNotifications'));
const ViewAdminRolePage = React.lazy(() => import('pages/roles/ViewAdminRolePage'));
const DuplicateRolePage = React.lazy(() => import('pages/roles/DuplicateRolePage'));

export default function Router() {
    return (
        <>
            <Switch>
                <Route
                    exact
                    path={Routes.home.path}
                    component={(props) => <CheckAuth {...props} route={Routes.home} component={null} />}
                />
                <Route
                    exact
                    path={Routes.admin.path}
                    component={(props) => <CheckAuth {...props} route={Routes.admin} component={AdminPage} />}
                />
                <Route
                    exact
                    path={Routes.adminProjects.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.adminProjects} component={AdminProjectsPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.adminProjectDetail.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.adminProjectDetail} component={AdminProjectDetailPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.adminOrders.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.adminOrders} component={AdminOrdersPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.browseInterviews.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.browseInterviews} component={BrowsePage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.adminUserInfo.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.adminUserInfo} component={AdminUserInfoPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.adminCreateAdmin.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.adminCreateAdmin} component={AdminCreateAdminPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.adminCreateClient.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.adminCreateClient} component={AdminCreateUserPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.adminCreateModerator.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.adminCreateModerator} component={AdminCreateUserPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.adminCreateRespondent.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.adminCreateRespondent} component={AdminCreateUserPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.adminCreateTranslator.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.adminCreateTranslator} component={AdminCreateUserPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.signUp.path}
                    component={(props) => <CheckAuth {...props} route={Routes.signUp} component={Register} />}
                />
                <Route
                    path={`${Routes.signUp.path}/:role`}
                    component={(props) => <CheckAuth {...props} route={Routes.signUp} component={Register} />}
                />
                <Route
                    path={`${Routes.signUpTeam.path}/:token`}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.signUpTeam} component={TeamRegisterPage} />
                    )}
                />
                <Route
                    path={Routes.expiredLink.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.expiredLink} component={ExpiredLinkPage} />
                    )}
                />
                <Route exact path={Routes.confirm.path} component={ConfirmPage} />
                <Route
                    path={`${Routes.confirmRegistration.path}`}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.confirmRegistration.path} component={ConfirmRegistration} />
                    )}
                />
                <Route
                    exact
                    path={Routes.signIn.path}
                    component={(props) => <CheckAuth {...props} route={Routes.signIn} component={LoginPage} />}
                />
                <Route
                    exact
                    path={Routes.resetPassword.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.resetPassword.path} component={ResetPasswordPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.projects.path}
                    component={(props) => <CheckAuth {...props} route={Routes.projects} component={ProjectsPage} />}
                />
                <Route
                    exact
                    path={Routes.newProject.path}
                    component={(props) => <CheckAuth {...props} route={Routes.newProject} component={NewProjectPage} />}
                />
                <Route
                    exact
                    path={Routes.editProject.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.editProject} component={EditProjectPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.projectDetail.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.projectDetail} component={ProjectDetailPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.interviews.path}
                    component={(props) => <CheckAuth {...props} route={Routes.interviews} component={InterviewsPage} />}
                />
                <Route
                    exact
                    path={Routes.adminProjectDetail.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.adminProjectDetail} component={AdminProjectDetailPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.inviteToInterviews.path}
                    // eslint-disable-next-line react/no-children-prop
                    children={(props) => (
                        <CheckAuth {...props} route={Routes.inviteToInterviews} component={InviteResourcesPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.interviewInvitations.path}
                    // eslint-disable-next-line react/no-children-prop
                    children={(props) => (
                        <CheckAuth {...props} route={Routes.interviewInvitations} component={InvitationsPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.interviewApplications.path}
                    // eslint-disable-next-line react/no-children-prop
                    children={(props) => (
                        <CheckAuth {...props} route={Routes.interviewApplications} component={ApplicationsPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.interviewDetail.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.interviewDetail} component={InterviewDetailPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.profileOrWelcome.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.profileOrWelcome} component={ProfileOrWelcomePage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.welcome.path}
                    component={(props) => <CheckAuth {...props} route={Routes.welcome} component={WelcomePage} />}
                />
                <Route
                    exact
                    path={Routes.profile.path}
                    component={(props) => <CheckAuth {...props} route={Routes.profile} component={ProfilePage} />}
                />
                <Route
                    exact
                    path={Routes.profileEdit.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.profileEdit} component={ProfileEditPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.roleCreation.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.roleCreation} component={CreateRolePage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.role.path}
                    component={(props) => <CheckAuth {...props} route={Routes.role} component={EditRolePage} />}
                />
                <Route
                    exact
                    path={Routes.duplicateRole.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.duplicateRole} component={DuplicateRolePage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.viewRoleId.path}
                    component={(props) => <CheckAuth {...props} route={Routes.viewRoleId} component={ViewRolePage} />}
                />
                <Route
                    exact
                    path={Routes.viewRole.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.viewRole} component={ViewAdminRolePage} />
                    )}
                />

                <Route
                    exact
                    path={Routes.checkout.path}
                    component={(props) => <CheckAuth {...props} route={Routes.checkout} component={CheckoutPage} />}
                />
                <Route
                    exact
                    path={Routes.payInResult.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.payInResult} component={PayInResultPage} />
                    )}
                />

                <Route
                    exact
                    path={Routes.screeners.path}
                    component={(props) => <CheckAuth {...props} route={Routes.screeners} component={ScreenersPage} />}
                />

                <Route
                    exact
                    path={Routes.createScreener.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.createScreener} component={CreateScreenerPage} />
                    )}
                />

                <Route
                    exact
                    path={Routes.editScreener.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.editScreener} component={EditScreenerPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.publicProfile.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.publicProfile} component={ProfilePublicPage} />
                    )}
                />
                <Route
                    path={Routes.chat.path}
                    component={(props) => <CheckAuth {...props} route={Routes.chat} component={ChatPage} />}
                />
                <Route
                    exact
                    path={Routes.interviewOnline.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.interviewOnline} component={InterviewPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.plans.path}
                    component={(props) => <CheckAuth {...props} route={Routes.plans} component={AdminPlansPage} />}
                />
                <Route exact path={Routes.observer.path} component={ObserverPage} />
                <Route exact path={Routes.observer.deprecatedPath} component={ObserverPage} />
                <Route exact path={Routes.signalUser.path} component={ObserverPage} />
                <Route
                    exact
                    path={Routes.disputes.path}
                    component={(props) => <CheckAuth {...props} route={Routes.disputes} component={DisputesPage} />}
                />
                <Route
                    exact
                    path={Routes.notifications.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.notifications} component={NotificationsPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.vouchers.path}
                    component={(props) => <CheckAuth {...props} route={Routes.vouchers} component={VouchersPage} />}
                />
                <Route
                    exact
                    path={Routes.promoCodes.path}
                    component={(props) => <CheckAuth {...props} route={Routes.promoCodes} component={PromoCodesPage} />}
                />
                <Route exact path={Routes.external.path} component={JoinExternalPage} />
                <Route exact path={Routes.external.deprecatedPath} component={JoinExternalPage} />
                <Route
                    exact
                    path={Routes.dashboard.path}
                    component={(props) => <CheckAuth {...props} route={Routes.dashboard} component={DashboardPage} />}
                />
                <Route
                    exact
                    path={Routes.waitingRoom.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.waitingRoom} component={WaitingRoomPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.instanceWaitingRoom.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.instanceWaitingRoom} component={InstanceWaitingRoomPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.moderatorWaitingRoom.path}
                    component={(props) => (
                        <CheckAuth
                            {...props}
                            route={Routes.moderatorWaitingRoom}
                            component={ModeratorWaitingRoomPage}
                        />
                    )}
                />
                <Route
                    exact
                    path={Routes.newInterviewCreation.path}
                    component={(props) => (
                        <CheckAuth
                            {...props}
                            route={Routes.newInterviewCreation}
                            component={NewInterviewCreationFormPage}
                        />
                    )}
                />
                <Route
                    exact
                    path={Routes.recording.path}
                    component={(props) => <CheckAuth {...props} route={Routes.recording} component={RecordingPage} />}
                />
            </Switch>
            {/*<Helmet>*/}
            {/*    <meta name="viewport" content="width=1366, initial-scale=1" />*/}
            {/*</Helmet>*/}
            <ToastNotifications />
        </>
    );
}
