import React, { createContext, Component } from 'react';
import { isObserver, isRespondent } from 'constants/UserRoles';
import Routes, { getNavCategory } from 'constants/Routes';
import makeRequest from 'makeRequest';
import uploadFile from 'uploadFile';
import { ACCOUNT_TYPES_COMPANY_ADMIN_FILTERS } from 'constants/AccountTypeInformation';
import { convertToOptions } from 'helpers/SelectUtils';
import ChatSocketController from 'controllers/ChatSocket';
import BackendSocketController from 'controllers/BackendSocket';
import { getBackendSignalURL, getBackendSocketPath } from 'components/common/apiPrefix';
import { debounce, noop } from 'lodash';
import { createRequestHelper } from 'helpers/requestHelper';
import { getLocalTimezone } from 'components/common/listConstants';
import UsersRoles from 'constants/UserRoles';
import { withRouter } from 'react-router-dom';
import { calculateCompleteProfile, isSkipQuestionnairePossible } from 'constants/UserProfileComplete';
import { getToken, isAdminLogged } from 'helpers/getToken';

export const UserContext = createContext({});
const AUTH_TOKEN = 'authToken';
const USER_TOKEN = 'userToken';

const DEFAULT_LIMIT = 10;

const DEFAULT_OBSERVER_STATE = {
    isFetched: false,
    error: null,
    interviewId: null,
};

const DEFAULT_EXTERNAL_STATE = {
    isFetched: true,
    error: null,
    interviewId: null,
    password: null,
};

const passwordVerificationHelper = createRequestHelper('passwordVerification');
const getDeletionInfoRequestHelper = createRequestHelper('getDeletionInfo');
const getAccountDeletionConfirmationRequestHelper = createRequestHelper('getAccountDeletionConfirmation');
const deleteAccountRequestHelper = createRequestHelper('deleteAccount');
const login2FARequestHelper = createRequestHelper('login2FA');
const loginRecoveryRequestHelper = createRequestHelper('loginRecovery');
const toggle2FARequestHelper = createRequestHelper('toggle2FA');
const getRecoveryCodesRequestHelper = createRequestHelper('getRecoveryCodes');
const resetRecoveryCodesRequestHelper = createRequestHelper('resetRecoveryCodes');
const checkIfSessionHasPasswordHelper = createRequestHelper('checkIfSessionHasPassword');

class UserContextProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageScrollbarRef: null,
            user: null,
            submissionGotIt: false,
            loaded: false,
            usersFetched: false,
            users: [],
            adminUser: null,
            page: 0,
            searchQuery: '',
            searchStatus: 'all',
            searchAccountType: 'all',
            usersRole: UsersRoles.companyAdmin,
            totalCount: null,
            sort: {
                column: 'status',
                isIncrease: true,
            },
            currencyCoef: { GBP: 1, USD: 1.25, EUR: 1.125 },
            emailToConfirm: null,
            publicProfile: null,
            profileLoaded: false,
            previousRoute: '/',
            justLoggedIn: false,
            profileReviews: [],
            averages: {},
            reviewsLoaded: false,
            reviewsCount: 0,
            observer: DEFAULT_OBSERVER_STATE,
            external: DEFAULT_EXTERNAL_STATE,
            clientFreeObservers: 0,
            showCurrency: 'GBP',
            showWelcomeModal: false,
            showAdditionalModal: false,
            showResetModal: false,
            showActivateModal: false,
            tryToApplyInterview: null,
            ...passwordVerificationHelper.initialState,
            ...login2FARequestHelper.initialState,
            ...toggle2FARequestHelper.initialState,
            ...getRecoveryCodesRequestHelper.initialState,
            ...resetRecoveryCodesRequestHelper.initialState,
            ...checkIfSessionHasPasswordHelper.initialState,
            headerPreviousRoute: '',
            scrollBarsEventHandler: noop,
            systemTimezone: 'Europe/London',
            validatePhoneNumberResult: null,
            adminSelectedUsers: [],
            fetched: false,
        };

        makeRequest.logout = this.logout;
        window.uc = this;
    }

    componentDidMount() {
        const timezone = getLocalTimezone();
        this.setState({ systemTimezone: timezone });
        this.getUserInfo();
        if (window.location.href.includes('loginAsUser')) {
            const sessionToken = window.location.href.split('=')[1];
            sessionStorage.setItem(USER_TOKEN, sessionToken);
            window.location.href = window.location.href.split('?loginAsUser')[0];
        }
        this.connectToBackendSocket();
    }

    componentDidUpdate(prevProps, prevState) {
        const prevUser = prevState.user;
        const currentUser = this.state.user;

        const isUserChanged = this.isUserChanged(prevUser, currentUser);
        if (isUserChanged) {
            this.getExchangeCourse(currentUser.currency);
            this.socketLogin(getToken());
            this.setState({ showCurrency: this.state.user.displayCurrency });
        }
    }

    setShowWelcomeModal = (value) => {
        this.setState({ showWelcomeModal: value });
    };

    setShowAdditionalModal = (value) => {
        this.setState({ showAdditionalModal: value });
    };

    setShowCurrency = async (value) => this.setState({ showCurrency: value });

    finishRegistration = async () => {
        const path = 'finishRegistration';
        const data = { authToken: getToken() };
        return makeRequest({ path, data }, async (err, res) => {
            if (err) {
                console.log('Finish error');
                return console.log(err);
            }
            this.setState({ user: res.user });
        });
    };

    isUserChanged = (prevUser, currentUser) => {
        const isUserFetched = !prevUser && currentUser;
        const isUserChanged = prevUser && currentUser && prevUser.id !== currentUser.id;

        return isUserFetched || isUserChanged;
    };

    isTimezoneChanged = (prevTimezone, currentTimezone) => {
        const isTimezoneFetched = !prevTimezone && currentTimezone;
        const isTimezoneChanged = prevTimezone && currentTimezone && prevTimezone !== currentTimezone;

        return isTimezoneFetched || isTimezoneChanged;
    };

    addAdminSelectedUser = (id) => {
        this.setState({
            adminSelectedUsers: [...this.state.adminSelectedUsers, id],
        });
    };

    addManyAdminSelectedUser = (ids) => {
        this.setState({
            adminSelectedUsers: [...this.state.adminSelectedUsers, ...ids],
        });
    };

    removeAdminSelectedUser = (id) => {
        const filteredList = [...this.state.adminSelectedUsers.filter((u) => u !== id)];
        this.setState({
            adminSelectedUsers: filteredList,
        });
    };

    removeManyAdminSelectedUser = (ids) => {
        const filteredList = [...this.state.adminSelectedUsers.filter((u) => !ids.includes(u))];
        this.setState({
            adminSelectedUsers: filteredList,
        });
    };

    clearAdminSelectedUsers = () => this.setState({ adminSelectedUsers: [] });

    multyResetPassword = async ({ ids }) => {
        const path = 'multyResetPassword';
        const data = { authToken: getToken(), ids };
        return makeRequest({ path, data });
    };

    multyActivateUsers = async ({ ids }) => {
        const path = 'multyActivateUsers';
        const data = { authToken: getToken(), ids };
        return makeRequest({ path, data });
    };

    setResetModal = (value) => {
        this.setState({ showResetModal: value });
    };

    setActivateModal = (value) => {
        this.setState({ showActivateModal: value });
    };

    getUserInfo = (cb = noop) => {
        const path = 'getUser';
        const tempToken = localStorage.getItem('tempToken');

        const sessionToken = getToken();
        const isAdmin = isAdminLogged();
        const userToken = window.location.href.split('loginAsUser=')[1] || sessionToken;

        if (userToken && isAdmin) {
            const data = { authToken: userToken };

            return makeRequest({ path, data }, async (err, data) => {
                const user = err ? null : data.user;

                this.setState({ user, loaded: true, fetched: true });

                if (user) {
                    this.setState({ showCurrency: user.displayCurrency });
                    this.getExchangeCourse(user.currency);
                }
                typeof cb === 'function' && cb();
            });
        }

        if (tempToken !== '' && tempToken !== 'undefined') {
            this.saveToken(tempToken);
            this.saveTempToken('');
        }
        const data = { authToken: getToken() };
        if (!data.authToken || data.authToken === 'undefined' || data.authToken === 'null') {
            this.setState({ loaded: true, fetched: true });
            return;
        }

        data.timezone = this.state.systemTimezone;
        return makeRequest({ path, data }, async (err, data) => {
            const user = err ? null : data.user;
            this.setState({ user, loaded: true });

            if (user) {
                this.setState({ fetched: true });
                this.setState({ showCurrency: user.displayCurrency });
                this.setState({ systemTimezone: user.timezone });
                this.getExchangeCourse(user.currency);
            }
            typeof cb === 'function' && cb();
        });
    };

    getUserActive = () => {
        const path = 'getUserActive';
        const data = { authToken: getToken() };
        return makeRequest({ path, data });
    };

    gotIt = () => {
        this.setState({ submissionGotIt: true });
    };

    setTryApplyingInterview = (interview) => {
        this.setState({ tryToApplyInterview: interview });
    };

    register = (data, cb) => {
        const path = 'register';
        data.timezone = this.state.systemTimezone;
        return makeRequest({ path, data }, (err, res) => {
            const { authToken, user } = res;
            if (authToken) {
                this.saveToken(authToken);
                this.setState({ user });
            }
            cb && cb(authToken);
            this.setState({ systemTimezone: user.timezone });
        });
    };

    registerTeamMember = (data, avatar, cb) => {
        const path = 'registerTeamMember';
        if (!avatar) {
            return this.register(data, cb);
        }
        const formData = new FormData();
        for (const key in data) {
            if (data[key]) {
                formData.append(key, data[key]);
            }
        }
        formData.append('avatar', avatar);
        return uploadFile({ path, data: formData }, (err, res) => {
            const { authToken, user } = res;
            if (authToken) {
                this.saveToken(authToken);
                this.setState({ user });
            }
            cb && cb(authToken);
        });
    };

    authApi = (data, cb) => {
        const path = 'authApi';

        return makeRequest({ path, data }, (err, res) => {
            if (err) {
                cb(err);
                return;
            }

            const { authToken, user } = res;
            const complete = calculateCompleteProfile(user);
            const shouldShowWelcomeModal =
                isRespondent(user.role) && !user.welcomeFilled && isSkipQuestionnairePossible(user.role, complete);
            this.saveToken(authToken);
            this.setState({ user, showWelcomeModal: shouldShowWelcomeModal });
            cb && cb(null, res);
        });
    };

    confirm = (data, cb) => {
        const path = 'confirm';

        return makeRequest({ path, data }, (err, res) => {
            if (err) {
                cb(err);
                return;
            }

            const { authToken, user } = res;
            this.saveToken(authToken);
            this.setState({ user });
            cb && cb(null, res);
        });
    };

    login = (data, cb) => {
        const path = 'login';
        data.timezone = this.state.systemTimezone;

        return makeRequest({ path, data }, (err, res) => {
            if (err) {
                cb(err);
                return;
            }

            cb && cb(null, res);
            if (res.twoFA) return;

            const { authToken, user } = res;
            const shouldShowWelcomeModal = isRespondent(user.role) && !user.welcomeFilled;
            this.saveToken(authToken);
            this.setState({ user, showWelcomeModal: shouldShowWelcomeModal });
            this.setState({ systemTimezone: user.timezone });
        });
    };

    loginAsUser = (data, cb) => {
        const path = 'loginAsUser';
        data.authToken = getToken();

        return makeRequest({ path, data }, (err, res) => {
            if (err) {
                cb && cb(err);
                return;
            }

            cb && cb(null, res);
            const { authToken } = res;

            const link = `/admin?loginAsUser=${authToken}`;
            window.open(link, '_blank');
            ChatSocketController.disconnect();
        });
    };

    logout = (withRedirect = true) => {
        const authToken = getToken();
        const path = 'logout';
        const data = { authToken };
        if (this.state.user) this.socketLogout(this.state.user.id);
        makeRequest({ path, data }, () => {
            localStorage.removeItem(AUTH_TOKEN);
            this.setState({ user: null });
            ChatSocketController.disconnect();
            withRedirect && this.props.history.push(Routes.browseInterviews.path);
            withRedirect && this.props.history.go(0);

            //to remove the auth token if the user has been logged in through the admin dashboard
            if (sessionStorage.getItem(USER_TOKEN)) {
                sessionStorage.removeItem(USER_TOKEN);
            }
        });
    };

    saveToken = (token) => localStorage.setItem(AUTH_TOKEN, token);
    saveTempToken = (token) => localStorage.setItem('tempToken', token);

    forgotPassword = (data, cb) => {
        const path = 'resetPassword';

        return makeRequest({ path, data }, cb);
    };

    resetPassword = (data, cb) => {
        const path = 'changePassword';

        return makeRequest({ path, data }, (err, res) => {
            cb && cb(err, res);

            if (err) return;

            const { authToken, user } = res;
            this.saveToken(authToken);
            this.setState({ user });
        });
    };

    getSocketId = async () => {
        const socket = await BackendSocketController.getSocket();
        return socket.id;
    };

    setPassword = async (data, cb) => {
        const path = 'setPassword';
        const socketId = await this.getSocketId();
        if (socketId) {
            data.socketId = socketId;
        }

        data.authToken = getToken();

        await makeRequest({ path, data });
    };

    closeSessions = async (data) => {
        const path = 'closeSessions';
        data.authToken = getToken();
        await makeRequest({ path, data });
    };

    setNewPasswordFromAdmin = (data, cb) => {
        const path = 'setNewPasswordFromAdmin';
        data.authToken = getToken();

        return makeRequest({ path, data }, cb);
    };

    activateUser = async (data) => {
        const path = 'activateUser';
        data.authToken = getToken();

        const res = await makeRequest({ path, data });
        this.setState({ adminUser: res.adminUser });
    };

    deactivateUser = async (data) => {
        const path = 'deactivateUser';
        data.authToken = getToken();

        const res = await makeRequest({ path, data });
        this.setState({ adminUser: res.adminUser });
    };

    blockUser = async (data) => {
        const path = 'blockUser';
        data.authToken = getToken();

        const res = await makeRequest({ path, data });
        this.setState({ adminUser: res.adminUser });
    };

    unblockUser = async (data) => {
        const path = 'unblockUser';
        data.authToken = getToken();

        const res = await makeRequest({ path, data });
        this.setState({ adminUser: res.adminUser });
    };

    deleteUser = async (data) => {
        const path = 'deleteUser';
        data.authToken = getToken();

        const { firstName, lastName } = await makeRequest({ path, data });
        // this.setState({adminUser: null});
        return { firstName, lastName };
    };

    removeApplyingInterview = async () => {
        const path = 'removeApplying';
        const data = { authToken: getToken() };
        await makeRequest({ path, data });
        this.setState((state) => ({
            user: {
                ...state.user,
                applyingInterview: null,
            },
        }));
    };

    setEmail = async (data, cb) => {
        const path = 'setEmail';
        data.authToken = getToken();

        await makeRequest({ path, data });
        this.setState((state) => ({
            user: {
                ...state.user,
                email: data.email,
            },
        }));
    };

    setAvatar = (file, cb) => {
        const path = 'setAvatar';
        const data = new FormData();
        data.append('avatar', file);
        data.append('authToken', getToken());

        return uploadFile({ path, data }, (err, res) => {
            cb && cb(err, res);

            if (err) return;

            this.setState({ user: res.user });
        });
    };

    saveProfile = (data, cb) => {
        const path = 'editProfile';
        data.authToken = getToken();
        return makeRequest({ path, data }, (err, res) => {
            if (!err) {
                const user = res.user;
                this.setState({ user });
            }
            cb && cb(err, res);
        });
    };

    saveCompanyInfo = (data, cb) => {
        const path = 'editCompanyInfo';
        data.authToken = getToken();

        return makeRequest({ path, data }, (err, res) => cb && cb(err, res));
    };

    setRespondentInfo = async (data, cb) => {
        const path = 'setRespondentInfo';
        data.authToken = getToken();

        return makeRequest({ path, data }, (err, res) => cb && cb(err, res));
    };

    setRespondentBusinessInfo = async (data, cb) => {
        const path = 'setRespondentBusinessInfo';
        data.authToken = getToken();
        const res = await makeRequest({ path, data });
        this.setState({ user: res.user });
    };

    deleteRespondentBusinessInfo = async (data, cb) => {
        const path = 'deleteRespondentBusinessInfo';
        data.authToken = getToken();

        const res = await makeRequest({ path, data });
        this.setState({ user: res.user });
        cb && cb(null, res);
    };

    setRespondentBusinessStatus = async (data, cb) => {
        const path = 'setRespondentBusinessStatus';
        data.authToken = getToken();

        const res = await makeRequest({ path, data });
        this.setState({ adminUser: res.adminUser });
    };

    setRespondentMedicalInfo = async (data, cb) => {
        const path = 'setRespondentMedicalInfo';
        data.authToken = getToken();

        const res = await makeRequest({ path, data });
        this.setState({ user: res.user });
        cb && cb(null, res);
    };

    deleteRespondentMedicalInfo = async (data, cb) => {
        const path = 'deleteRespondentMedicalInfo';
        data.authToken = getToken();

        const res = await makeRequest({ path, data });
        this.setState({ user: res.user });
        cb && cb(null, res);
    };

    setRespondentMedicalStatus = async (data, cb) => {
        const path = 'setRespondentMedicalStatus';
        data.authToken = getToken();

        const res = await makeRequest({ path, data });
        this.setState({ adminUser: res.adminUser });
    };

    getUsers = async (data) => {
        if (!data.isSort) {
            this.setState({
                usersFetched: false,
            });
        }

        data.authToken = getToken();

        if (!data.accountType) {
            data.accountType =
                data.role === this.state.usersRole
                    ? this.state.searchAccountType
                    : convertToOptions(ACCOUNT_TYPES_COMPANY_ADMIN_FILTERS)[0].value;
        }

        if (!data.sort) {
            data.sort = this.state.sort;
        }
        if (!data.limit) {
            data.limit = DEFAULT_LIMIT;
        }
        data.skip = data.page * data.limit;

        const page = typeof data.page !== 'undefined' ? data.page : this.state.page;
        const update = {
            searchQuery: data.searchQuery,
            searchStatus: data.searchStatus,
            searchAccountType: data.searchAccountType,
            usersRole: data.usersRole,
            sort: data.sort,
        };
        this.setState({
            page,
            ...update,
        });

        return this.makeGetUsersRequest(data);
    };

    makeGetUsersRequest = debounce(async (data) => {
        const path = 'getUsers';
        const res = await makeRequest({ path, data });
        this.setState({
            users: res.users,
            totalCount: res.totalCount,
            usersFetched: true,
        });
        return true;
    }, 500);

    getUserById = async (data) => {
        const path = 'getUserById';
        data.authToken = getToken();

        const result = await makeRequest({ path, data });
        this.setState({ adminUser: result.user });
    };

    clearGetUserById = () => {
        this.setState({
            adminUser: null,
        });
    };

    createNewAdmin = (data, cb) => {
        const path = 'createNewAdmin';
        data.authToken = getToken();

        return makeRequest({ path, data }, (err, res) => cb && cb(err, res));
    };

    createNewUser = async (data, cb) => {
        const path = 'createNewUser';
        data.authToken = getToken();

        await makeRequest({ path, data });
    };

    fetchOutsideCompanyUsers = async (data) => {
        const path = 'users/outsideCompany';
        data.authToken = getToken();
        data.offset = 0;
        data.limit = 100;

        const result = await makeRequest({ path, data });
        this.setState({
            outsideCompanyUsers: result,
        });
    };

    getExchangeCourse(currency) {
        const path = 'currency';
        makeRequest({ path, data: { currency } }, (err, res) => {
            if (err) {
                console.log(err);
            } else {
                this.setState({ currencyCoef: res });
            }
        });
    }

    resendConfirmationToEmail(email) {
        const path = 'resendEmailToUser';
        makeRequest({ path, data: { email } }, (err, res) => {
            if (err) console.log(err);
        });
    }

    updatePublicProfile = (publicProfile) => {
        this.setState({ publicProfile });
    };

    getPublicInfo = (id) => {
        const path = 'getPublicUserInfo';

        this.setState({ profileLoaded: false, profileReviews: [] });

        makeRequest({ path, data: { id, authToken: getToken() } }, (err, res) => {
            if (err) {
                this.setState({ profileLoaded: true });
            } else {
                this.setState({ publicProfile: res.user, profileLoaded: true });
            }
        });
    };

    clearPublicProfile = () => {
        this.setState({ publicProfile: null });
    };

    setEmailToConfirm = (emailToConfirm) => this.setState({ emailToConfirm });
    setPageScrollbarRef = (pageScrollbarRef) => this.setState({ pageScrollbarRef });

    setPreviousRoute = (route) => {
        this.setState({
            previousRoute: {
                route,
                navCategory: getNavCategory(route) || this.state.previousRoute.navCategory,
            },
        });
    };

    setJustLoggedIn = (status) => this.setState({ justLoggedIn: status });

    getReviews = async (userId, offset, limit, onLoadHandler) => {
        const path = 'getReviews';

        this.setState({ reviewsLoaded: false });

        const reqData = { userId, offset, limit, authToken: getToken() };

        makeRequest({ path, data: reqData }, (err, res) => {
            if (err) {
                this.setState({ reviewsLoaded: false });
            } else {
                const newReviews = this.state.profileReviews.concat(res.reviews);
                onLoadHandler && onLoadHandler();
                this.setState({
                    reviewsLoaded: true,
                    profileReviews: newReviews,
                    averages: res.averages,
                    reviewsCount: res.reviewsCount,
                });
            }
        });
    };

    clearReviews = () => {
        this.setState({ reviewsLoaded: false, profileReviews: [], averages: {}, reviewsCount: 0 });
    };

    reviewUser = async (reviewedId, interviewId, ratings, text, cb) => {
        const path = 'reviewUser';

        const reqData = {
            reviewedId,
            interviewId,
            ratings,
            text,
            authToken: getToken(),
        };

        makeRequest({ path, data: reqData }, (err, res) => {
            if (err) return console.log(err);

            cb && cb();
        });
    };

    async connectToBackendSocket() {
        await BackendSocketController.connect(getBackendSignalURL(), {}, getBackendSocketPath());
        this.applyBackendSocketEventListeners();
    }

    socketLogin() {
        const token = getToken();
        BackendSocketController.emit('auth::login', { token });
        BackendSocketController.on('reconnecting', () => {
            BackendSocketController.emit('auth::login', { token });
        });
    }

    socketLogout(userId) {
        BackendSocketController.emit('auth::logout', { userId });
        BackendSocketController.off('reconnecting');
    }

    applyBackendSocketEventListeners() {
        BackendSocketController.on('auth::deactivate', () => {
            this.setState({ user: { ...this.state.user, active: false } });
        });
        BackendSocketController.on('auth::activate', () => {
            this.setState({ user: { ...this.state.user, active: true } });
        });
        BackendSocketController.on('auth::block', this.logout);
        BackendSocketController.on('auth::delete', this.logout);
        BackendSocketController.on('user:update', this.getUserInfo);
        BackendSocketController.on('user::session_logout', async (socketId) => {
            const socket = await BackendSocketController.getSocket();
            if (socket && socket.id && socket.id === socketId) {
                return;
            }

            this.logout();
        });
    }

    createObserver = async ({ observerToken, password, signalUserToken, name }) => {
        const path = 'interviews/createObserver';
        const token = getToken();

        const data = {
            observerToken,
            authToken: token,
            password,
            signalUserToken,
            name,
        };
        const { user: loggedInUser } = this.state;
        if (loggedInUser && !isObserver(loggedInUser.role)) {
            this.saveTempToken(token);
            this.saveToken('');
        }

        try {
            const { user, authToken, interviewId, sessionId } = await makeRequest({ path, data });
            this.saveToken(authToken);
            this.setState({ user });

            this.setState({
                observer: {
                    ...DEFAULT_OBSERVER_STATE,
                    isFetched: true,
                    interviewId,
                    sessionId,
                },
            });
        } catch (e) {
            this.setState({
                observer: {
                    ...DEFAULT_OBSERVER_STATE,
                    isFetched: true,
                    error: e,
                },
            });
        }
    };

    clearObserver = () => {
        this.setState({ observer: DEFAULT_OBSERVER_STATE });
    };

    createExternal = async (externalToken, name, password) => {
        const path = 'interviews/createExternalResource';
        const token = getToken();

        const data = {
            externalToken,
            authToken: token,
            name,
            password,
        };

        this.setState({
            external: {
                ...DEFAULT_EXTERNAL_STATE,
                isFetched: false,
            },
        });
        try {
            const { user, authToken, interviewId, sessionId, isExternalModerator, isStarted } = await makeRequest({
                path,
                data,
            });
            this.saveToken(authToken);
            this.setState({ user });

            this.setState({
                external: {
                    ...DEFAULT_EXTERNAL_STATE,
                    isFetched: true,
                    interviewId,
                    sessionId,
                    isExternalModerator,
                    isStarted,
                },
            });
        } catch (e) {
            this.setState({
                external: {
                    ...DEFAULT_EXTERNAL_STATE,
                    isFetched: true,
                    error: e,
                },
            });
        }
    };

    clearExternal = () => this.setState({ external: DEFAULT_EXTERNAL_STATE });

    checkIfExternalInterviewOwner = async (interviewToken) => {
        const path = 'interviews/checkIfExternalInterviewOwner';
        const authToken = getToken();
        const data = {
            interviewToken,
            authToken,
        };

        this.setState({
            external: {
                ...DEFAULT_EXTERNAL_STATE,
                isFetched: false,
            },
        });

        try {
            const { interviewId } = await makeRequest({ path, data });
            this.setState({
                external: {
                    ...DEFAULT_EXTERNAL_STATE,
                    isFetched: true,
                    interviewId,
                    isOwner: true,
                },
            });
        } catch (e) {
            this.setState({
                external: {
                    ...DEFAULT_EXTERNAL_STATE,
                    isFetched: true,
                    isOwner: false,
                },
            });
        }
    };

    checkIfSessionHasPassword = async (interviewToken) => {
        const path = 'interviews/checkIfSessionHasPassword';
        const data = { interviewToken };

        this.setState(checkIfSessionHasPasswordHelper.processing());
        try {
            const result = await makeRequest({ path, data });
            this.setState(checkIfSessionHasPasswordHelper.result(result));
        } catch (e) {
            this.setState(checkIfSessionHasPasswordHelper.error(e));
        }
    };

    verifyPassword = async (password) => {
        const path = 'verifyPassword';
        const authToken = getToken();

        try {
            this.setState(passwordVerificationHelper.processing());
            await makeRequest({ path, data: { password, authToken } });
            this.setState(passwordVerificationHelper.result(true));
        } catch (error) {
            this.setState(passwordVerificationHelper.error(error));
        }
    };
    clearPasswordVerification = () => this.setState(passwordVerificationHelper.clear());

    updateKycState = (kycDocuments) => this.setState({ user: { ...this.state.user, kycDocuments } });
    updateDeclarationState = (uboDeclaration) => this.setState({ user: { ...this.state.user, uboDeclaration } });
    updateBalanceState = ({ balance, frozen }) => this.setState({ user: { ...this.state.user, frozen, balance } });

    saveHeaderPreviousRoute = (path) => this.setState({ headerPreviousRoute: path });

    setScrollBarsEventHandler = (handler) => this.setState({ scrollBarsEventHandler: handler });

    setUser = (user) => this.setState({ user });

    validatePhoneNumber = async (phoneNumber) => {
        const path = 'validatePhoneNumber';
        const authToken = getToken();

        try {
            const result = await makeRequest({ path, data: { authToken, phoneNumber } });
            this.setState({ validatePhoneNumberResult: result });
            return result;
        } catch (error) {
            this.setState({ validatePhoneNumberResult: { valid: false } });
            return error;
        }
    };
    clearPhoneNumberValidation = () => this.setState({ validatePhoneNumberResult: null });

    getDeletionInfo = async () => {
        const path = 'getDeletionInfo';
        const authToken = getToken();

        this.setState(getDeletionInfoRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data: { authToken } });
            this.setState(getDeletionInfoRequestHelper.result(result));
        } catch (e) {
            this.setState(getDeletionInfoRequestHelper.error(e));
        }
    };
    clearGetDeletionInfo = () => this.setState(getDeletionInfoRequestHelper.clear());

    getAccountDeletionConfirmation = async () => {
        const path = 'getAccountDeletionConfirmation';
        const authToken = getToken();

        this.setState(getAccountDeletionConfirmationRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data: { authToken } });
            this.setState(getAccountDeletionConfirmationRequestHelper.result(result));
        } catch (e) {
            this.setState(getAccountDeletionConfirmationRequestHelper.error(e));
        }
    };

    deleteAccount = async (token, cb = noop) => {
        const path = 'deleteAccount';
        const authToken = getToken();

        this.setState(deleteAccountRequestHelper.processing());
        try {
            await makeRequest({ path, data: { authToken, token } });
            this.logout();
        } catch (e) {
            cb(e);
            this.setState(deleteAccountRequestHelper.error(e));
        }
    };

    login2FA = async (data, cb = noop) => {
        const path = 'login2FA';

        this.setState(login2FARequestHelper.processing());
        try {
            const result = await makeRequest({ path, data });
            const { user, authToken } = result;
            this.saveToken(authToken);
            this.setState({ user });
            cb(null, result);
        } catch (e) {
            this.setState(login2FARequestHelper.error(e));
            cb(e);
        }
    };

    loginRecovery = async (data, cb = noop) => {
        const path = 'loginRecovery';

        this.setState(loginRecoveryRequestHelper.processing());
        try {
            const { user, authToken } = await makeRequest({ path, data });
            this.saveToken(authToken);
            this.setState({ user });
            cb(null, { user, authToken });
        } catch (e) {
            this.setState(loginRecoveryRequestHelper.error(e));
            cb(e);
        }
    };

    toggle2FA = async (enabled, password, cb = noop) => {
        const path = enabled ? 'enable2FA' : 'disable2FA';
        const authToken = getToken();

        this.setState(toggle2FARequestHelper.processing());
        try {
            const { authyId } = await makeRequest({ path, data: { authToken, password } });
            this.setState({
                ...toggle2FARequestHelper.result({ authyId }),
                user: { ...this.state.user, authyId },
            });
            cb();
        } catch (e) {
            this.setState(toggle2FARequestHelper.error(e));
            cb(e);
        }
    };

    getRecoveryCodes = async () => {
        const path = 'getRecoveryCodes';
        const authToken = getToken();

        this.setState(getRecoveryCodesRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data: { authToken } });
            this.setState(getRecoveryCodesRequestHelper.result(result));
        } catch (e) {
            this.setState(getRecoveryCodesRequestHelper.error(e));
        }
    };

    resetRecoveryCodes = async () => {
        const path = 'regenerateRecoveryCodes';
        const authToken = getToken();

        this.setState(resetRecoveryCodesRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data: { authToken } });
            this.setState({
                ...getRecoveryCodesRequestHelper.result(result),
                ...resetRecoveryCodesRequestHelper.result({}),
            });
        } catch (e) {
            this.setState(resetRecoveryCodesRequestHelper.error(e));
        }
    };

    request2FAToken = async (data) => {
        const path = 'request2FAToken';
        await makeRequest({ path, data });
    };

    requestSMSConfirmation = async () => {
        const path = 'requestSMSConfirmation';
        const authToken = getToken();

        await makeRequest({ path, data: { authToken } });
    };

    saveTestConnectionResult = async (data) => {
        const path = 'saveTestConnectionResult';
        const authToken = getToken();
        try {
            await makeRequest({ path, data: { authToken, ...data } });
            this.setState({ user: { ...this.state.user, testConnection: { ...data }, testDone: true } });
        } catch (e) {
            console.log('Error sending test data');
        }
    };

    savePageToReturn = (page) => this.setState({ pageToReturn: page });

    render() {
        const actions = {
            gotIt: this.gotIt,
            register: this.register,
            authApi: this.authApi,
            confirm: this.confirm,
            login: this.login,
            loginAsUser: this.loginAsUser,
            logout: this.logout,
            saveToken: this.saveToken,
            forgotPassword: this.forgotPassword,
            resetPassword: this.resetPassword,
            getSocketId: this.getSocketId,
            saveProfile: this.saveProfile,
            saveCompanyInfo: this.saveCompanyInfo,
            getUserInfo: this.getUserInfo,
            getUserActive: this.getUserActive,
            setPassword: this.setPassword,
            setNewPasswordFromAdmin: this.setNewPasswordFromAdmin,
            activateUser: this.activateUser,
            deactivateUser: this.deactivateUser,
            blockUser: this.blockUser,
            unblockUser: this.unblockUser,
            deleteUser: this.deleteUser,
            setEmail: this.setEmail,
            setAvatar: this.setAvatar,
            setRespondentInfo: this.setRespondentInfo,
            setRespondentBusinessInfo: this.setRespondentBusinessInfo,
            deleteRespondentBusinessInfo: this.deleteRespondentBusinessInfo,
            setRespondentBusinessStatus: this.setRespondentBusinessStatus,
            setRespondentMedicalInfo: this.setRespondentMedicalInfo,
            deleteRespondentMedicalInfo: this.deleteRespondentMedicalInfo,
            setRespondentMedicalStatus: this.setRespondentMedicalStatus,
            getUsers: this.getUsers,
            getUserById: this.getUserById,
            clearGetUserById: this.clearGetUserById,
            createNewAdmin: this.createNewAdmin,
            createNewUser: this.createNewUser,
            fetchOutsideCompanyUsers: this.fetchOutsideCompanyUsers,
            setEmailToConfirm: this.setEmailToConfirm,
            setPageScrollbarRef: this.setPageScrollbarRef,
            resendConfirmationToEmail: this.resendConfirmationToEmail,
            isUserChanged: this.isUserChanged,
            getPublicInfo: this.getPublicInfo,
            setPreviousRoute: this.setPreviousRoute,
            setJustLoggedIn: this.setJustLoggedIn,
            getReviews: this.getReviews,
            clearReviews: this.clearReviews,
            reviewUser: this.reviewUser,
            clearPublicProfile: this.clearPublicProfile,
            createObserver: this.createObserver,
            clearObserver: this.clearObserver,
            debouncedVerifyPassword: debounce(this.verifyPassword, 500),
            updateKycState: this.updateKycState,
            updateDeclarationState: this.updateDeclarationState,
            updateBalanceState: this.updateBalanceState,
            clearPasswordVerification: this.clearPasswordVerification,
            setShowCurrency: this.setShowCurrency,
            finishRegistration: this.finishRegistration,
            saveHeaderPreviousRoute: this.saveHeaderPreviousRoute,
            setScrollBarsEventHandler: this.setScrollBarsEventHandler,
            debouncedGetUsers: debounce(this.getUsers, 500),
            setUser: this.setUser,
            validatePhoneNumber: this.validatePhoneNumber,
            updatePublicProfile: this.updatePublicProfile,
            clearPhoneNumberValidation: this.clearPhoneNumberValidation,
            getDeletionInfo: this.getDeletionInfo,
            getAccountDeletionConfirmation: this.getAccountDeletionConfirmation,
            deleteAccount: this.deleteAccount,
            login2FA: this.login2FA,
            loginRecovery: this.loginRecovery,
            toggle2FA: this.toggle2FA,
            getRecoveryCodes: this.getRecoveryCodes,
            resetRecoveryCodes: this.resetRecoveryCodes,
            request2FAToken: this.request2FAToken,
            requestSMSConfirmation: this.requestSMSConfirmation,
            saveTempToken: this.saveTempToken,
            saveTestConnectionResult: this.saveTestConnectionResult,
            createExternal: this.createExternal,
            checkIfExternalInterviewOwner: this.checkIfExternalInterviewOwner,
            checkIfSessionHasPassword: this.checkIfSessionHasPassword,
            savePageToReturn: this.savePageToReturn,
            clearExternal: this.clearExternal,
            removeApplyingInterview: this.removeApplyingInterview,
            setShowWelcomeModal: this.setShowWelcomeModal,
            setShowAdditionalModal: this.setShowAdditionalModal,
            setTryApplyingInterview: this.setTryApplyingInterview,
            closeSessions: this.closeSessions,
            addAdminSelectedUser: this.addAdminSelectedUser,
            addManyAdminSelectedUser: this.addManyAdminSelectedUser,
            removeAdminSelectedUser: this.removeAdminSelectedUser,
            removeManyAdminSelectedUser: this.removeManyAdminSelectedUser,
            clearAdminSelectedUsers: this.clearAdminSelectedUsers,
            multyResetPassword: this.multyResetPassword,
            multyActivateUsers: this.multyActivateUsers,
            setResetModal: this.setResetModal,
            setActivateModal: this.setActivateModal,
            registerTeamMember: this.registerTeamMember,
        };

        return (
            <UserContext.Provider value={{ ...this.state, ...actions }}>
                {this.state.loaded && this.props.children}
            </UserContext.Provider>
        );
    }
}

export default withRouter(UserContextProvider);

export const UserContextConsumer = function(WrappedComponent) {
    return function(props) {
        return (
            <UserContext.Consumer>
                {(context) => <WrappedComponent userContext={context} {...props} />}
            </UserContext.Consumer>
        );
    };
};
